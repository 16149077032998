import { IconProps } from '@/components/web/icons/types';

const IconCrossArrows: React.FC<IconProps> = ({
  width = '28',
  height = '28',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26 19.3333V26M26 26H19.3333M26 26L18 18M2 2L10 10M19.3333 2H26M26 2V8.66667M26 2L2 26'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconCrossArrows;
