import { IconProps } from '@/components/web/icons/types';

const IconUmbrella: React.FC<IconProps> = ({
  width = '16',
  height = '16',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2984_8098)'>
        <path
          d='M4.66668 12.9333C4.66668 13.8906 5.41287 14.6666 6.33334 14.6666C7.25382 14.6666 8.00001 13.8906 8.00001 12.9333V7.33331M8.00001 7.33331C6.92656 7.33331 5.33334 7.99998 5.33334 7.99998C5.33334 7.99998 4.40679 7.33331 3.33334 7.33331C2.25989 7.33331 1.33334 7.99998 1.33334 7.99998C1.33334 4.31808 4.31811 1.33331 8.00001 1.33331C11.6819 1.33331 14.6667 4.31808 14.6667 7.99998C14.6667 7.99998 13.7401 7.33331 12.6667 7.33331C11.5932 7.33331 10.6667 7.99998 10.6667 7.99998C10.6667 7.99998 9.07346 7.33331 8.00001 7.33331Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2984_8098'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconUmbrella;
