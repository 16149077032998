import { IconProps } from '@/components/web/icons/types';

const IconChartUp: React.FC<IconProps> = ({
  width = '16',
  height = '16',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2984_8101)'>
        <path
          d='M10.3333 2.33331V1.33331M12.9595 3.04042L13.6666 2.33331M13.6735 5.66665H14.6735M14.6337 8.66665C14.2992 12.0355 11.4569 14.6666 7.99998 14.6666C4.31808 14.6666 1.33331 11.6819 1.33331 7.99998C1.33331 4.54308 3.96443 1.70071 7.33331 1.36623M7.99998 5.33331H10.6666V7.99998M10.4131 5.33331C8.84353 7.55171 6.25755 8.99998 3.33331 8.99998C2.66471 8.99998 2.01379 8.92427 1.38866 8.78095'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2984_8101'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconChartUp;
