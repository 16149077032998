import { IconProps } from '@/components/web/icons/types';

const IconShield: React.FC<IconProps> = ({
  width = '32',
  height = '32',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.0693 28.8198C15.3645 28.992 15.5121 29.0781 15.7204 29.1228C15.8821 29.1575 16.1179 29.1575 16.2796 29.1228C16.4879 29.0781 16.6354 28.992 16.9306 28.8198C19.528 27.3045 26.6666 22.5446 26.6666 16V10.9333C26.6666 9.50146 26.6666 8.78553 26.4459 8.27732C26.2216 7.76079 25.9981 7.48601 25.5381 7.1612C25.0854 6.84162 24.1981 6.65709 22.4236 6.28802C20.4677 5.88124 18.9657 5.1467 17.5925 4.08441C16.934 3.57501 16.6047 3.32031 16.3471 3.25084C16.0752 3.17754 15.9247 3.17754 15.6529 3.25084C15.3952 3.32031 15.066 3.57501 14.4075 4.08441C13.0343 5.1467 11.5322 5.88124 9.57638 6.28802C7.80181 6.65709 6.91453 6.84162 6.46191 7.1612C6.00187 7.48601 5.77836 7.76079 5.55403 8.27732C5.33331 8.78553 5.33331 9.50146 5.33331 10.9333V16C5.33331 22.5446 12.4719 27.3045 15.0693 28.8198Z'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconShield;
