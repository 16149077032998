import { IconProps } from '@/components/web/icons/types';

const IconLock: React.FC<IconProps> = ({
  width = '14',
  height = '14',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.3334 5.66667V4.33333C10.3334 2.49238 8.84097 1 7.00002 1C5.15907 1 3.66669 2.49238 3.66669 4.33333V5.66667M7.00002 8.66667V10M4.86669 13H9.13335C10.2535 13 10.8135 13 11.2413 12.782C11.6177 12.5903 11.9236 12.2843 12.1154 11.908C12.3334 11.4802 12.3334 10.9201 12.3334 9.8V8.86667C12.3334 7.74656 12.3334 7.18651 12.1154 6.75869C11.9236 6.38236 11.6177 6.0764 11.2413 5.88465C10.8135 5.66667 10.2535 5.66667 9.13335 5.66667H4.86669C3.74658 5.66667 3.18653 5.66667 2.75871 5.88465C2.38238 6.0764 2.07642 6.38236 1.88467 6.75869C1.66669 7.18651 1.66669 7.74656 1.66669 8.86667V9.8C1.66669 10.9201 1.66669 11.4802 1.88467 11.908C2.07642 12.2843 2.38238 12.5903 2.75871 12.782C3.18653 13 3.74658 13 4.86669 13Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconLock;
