import { IconProps } from '@/components/web/icons/types';

const IconLocalMark: React.FC<IconProps> = ({
  width = '16',
  height = '16',
  color = '#FFFFFF',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3333 8C13.3333 10.9455 10.9455 13.3333 7.99998 13.3333M13.3333 8C13.3333 5.05448 10.9455 2.66667 7.99998 2.66667M13.3333 8H14.6666M7.99998 13.3333C5.05446 13.3333 2.66665 10.9455 2.66665 8M7.99998 13.3333V14.6667M2.66665 8C2.66665 5.05448 5.05446 2.66667 7.99998 2.66667M2.66665 8H1.33331M7.99998 2.66667V1.33334M9.99998 8C9.99998 9.10457 9.10455 10 7.99998 10C6.89541 10 5.99998 9.10457 5.99998 8C5.99998 6.89543 6.89541 6 7.99998 6C9.10455 6 9.99998 6.89543 9.99998 8Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconLocalMark;
