import clsx, { ClassValue } from "clsx";
import { useMediaQuery } from "react-responsive";
import { twMerge } from "tailwind-merge";

/** Merge classes with tailwind-merge with clsx full feature */
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function use4K() {
    const FK = useMediaQuery({ query: "(min-width: 2160px)" });
    return FK;
}

export function use4XL() {
    const FXL = useMediaQuery({ query: "(min-width: 1920px)" });
    return FXL;
}

export function use2XL() {
    const TXL = useMediaQuery({ query: "(min-width: 1536px)" });
    return TXL;
}

export function useXL() {
    const XL = useMediaQuery({ query: "(min-width: 1280px)" });
    return XL;
}

export function useLG() {
    const LG = useMediaQuery({ query: "(min-width: 1024px)" });
    return LG;
}

export function useMD() {
    const MD = useMediaQuery({ query: "(min-width: 768px)" });
    return MD;
}

export function useSM() {
    const SM = useMediaQuery({ query: "(min-width: 640px)" });
    return SM;
}

export function useXS() {
    const XS = useMediaQuery({ query: "(min-width: 500px)" });
    return XS;
}

export function use2XS() {
    const TXS = useMediaQuery({ query: "(min-width: 480px)" });
    return TXS;
}

export function use4XS() {
    const FXS = useMediaQuery({ query: "(min-width: 320px)" });
    return FXS;
}
