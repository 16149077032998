import { FC } from 'react';

type IconProps = {
  width?: string;
  height?: string;
  color?: string;
};

const IconBurgerMenu: FC<IconProps> = ({
  width = '16',
  height = '12',
  color = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.25 6H14.75M1.25 1.5H14.75M1.25 10.5H14.75'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default IconBurgerMenu;
