import { IconProps } from '@/components/web/icons/types';

const IconVerified: React.FC<IconProps> = ({
  width = '32',
  height = '32',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 16L14.6667 18.6666L20.6667 12.6666M12.2692 27.4678C12.7062 27.41 13.1477 27.5285 13.4958 27.7967L15.1001 29.0277C15.6304 29.4351 16.3681 29.4351 16.897 29.0277L18.562 27.7493C18.8731 27.5108 19.2657 27.4056 19.6538 27.4574L21.7366 27.7315C22.3987 27.8189 23.0372 27.45 23.2935 26.8323L24.0949 24.8945C24.2445 24.5316 24.5319 24.2442 24.8948 24.0946L26.8325 23.2931C27.4502 23.0383 27.819 22.3983 27.7316 21.7361L27.468 19.7287C27.4102 19.2917 27.5287 18.8503 27.7968 18.5021L29.0278 16.8977C29.4352 16.3674 29.4352 15.6296 29.0278 15.1007L27.7494 13.4356C27.5109 13.1245 27.4057 12.7319 27.4576 12.3438L27.7316 10.2609C27.819 9.59866 27.4502 8.96016 26.8325 8.70387L24.8948 7.90241C24.5319 7.75278 24.2445 7.46538 24.0949 7.10243L23.2935 5.1647C23.0387 4.54694 22.3987 4.17807 21.7366 4.26547L19.6538 4.53954C19.2657 4.59287 18.8731 4.48769 18.5635 4.25066L16.8985 2.97217C16.3681 2.56478 15.6304 2.56478 15.1016 2.97217L13.4365 4.25066C13.1254 4.48769 12.7329 4.59287 12.3448 4.5425L10.262 4.26843C9.59981 4.18103 8.96135 4.54991 8.70507 5.16767L7.90514 7.10539C7.75404 7.46686 7.46666 7.75426 7.10521 7.90537L5.16759 8.70535C4.54986 8.96164 4.181 9.60014 4.26841 10.2623L4.54246 12.3452C4.59282 12.7334 4.48765 13.126 4.25063 13.4356L2.97222 15.1007C2.56484 15.6311 2.56484 16.3688 2.97222 16.8977L4.25063 18.5629C4.48913 18.874 4.5943 19.2665 4.54246 19.6547L4.26841 21.7376C4.181 22.3998 4.54986 23.0383 5.16759 23.2946L7.10521 24.096C7.46814 24.2457 7.75552 24.5331 7.90514 24.896L8.70655 26.8337C8.96135 27.4515 9.60129 27.8204 10.2635 27.733L12.2692 27.4678Z'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconVerified;
