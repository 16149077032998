import IconArrowRight from '@/components/web/icons/IconArrowRight';
import IconBolt from '@/components/web/icons/IconBolt';
import IconBurgerMenu from '@/components/web/icons/IconBurgerMenu';
import IconChartUp from '@/components/web/icons/IconChartUp';
import IconCheck from '@/components/web/icons/IconCheck';
import IconChevron from '@/components/web/icons/IconChevron';
import IconChevronWithBg from '@/components/web/icons/IconChevronWithBg';
import IconClose from '@/components/web/icons/IconClose';
import IconCrossArrows from '@/components/web/icons/IconCrossArrows';
import IconHeart from '@/components/web/icons/IconHeart';
import IconLocalMark from '@/components/web/icons/IconLocalMark';
import IconLocationWithBg from '@/components/web/icons/IconLocationWithBg';
import IconLock from '@/components/web/icons/IconLock';
import IconMedal from '@/components/web/icons/IconMedal';
import IconPeople from '@/components/web/icons/IconPeople';
import IconScale from '@/components/web/icons/IconScale';
import IconShield from '@/components/web/icons/IconShield';
import IconUmbrella from '@/components/web/icons/IconUmbrella';
import IconVerified from '@/components/web/icons/IconVerified';

export {
  IconArrowRight,
  IconBolt,
  IconBurgerMenu,
  IconChartUp,
  IconCheck,
  IconChevron,
  IconChevronWithBg,
  IconClose,
  IconCrossArrows,
  IconHeart,
  IconLocalMark,
  IconLocationWithBg,
  IconLock,
  IconMedal,
  IconPeople,
  IconScale,
  IconShield,
  IconUmbrella,
  IconVerified,
};
