import { IconProps } from '@/components/web/icons/types';

const IconHeart: React.FC<IconProps> = ({
  width = '32',
  height = '28',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.9909 4.84775C13.3251 1.7312 8.87968 0.892857 5.53961 3.74668C2.19955 6.60051 1.72931 11.372 4.35228 14.7472C6.53311 17.5535 13.1331 23.4721 15.2962 25.3878C15.5382 25.6021 15.6592 25.7093 15.8003 25.7514C15.9235 25.7881 16.0583 25.7881 16.1815 25.7514C16.3226 25.7093 16.4436 25.6021 16.6856 25.3878C18.8487 23.4721 25.4487 17.5535 27.6295 14.7472C30.2525 11.372 29.8396 6.57049 26.4422 3.74668C23.0447 0.922876 18.6567 1.7312 15.9909 4.84775Z'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconHeart;
