import { IconProps } from "@/components/web/icons/types";

const IconChevron: React.FC<IconProps> = ({ width = "6", height = "11", color = "#DD0B2F", className }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 6 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M1 9.5L5 5.5L1 1.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconChevron;
