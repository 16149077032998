import { IconProps } from '@/components/web/icons/types';

const IconBolt: React.FC<IconProps> = ({
  width = '28',
  height = '32',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3334 2.66669L3.45796 16.9172C2.99288 17.4753 2.76034 17.7543 2.75679 17.99C2.7537 18.1948 2.84499 18.3898 3.00436 18.5185C3.18768 18.6667 3.55092 18.6667 4.2774 18.6667H14L12.6667 29.3334L24.5421 15.0829C25.0072 14.5248 25.2397 14.2457 25.2433 14.0101C25.2463 13.8052 25.155 13.6103 24.9957 13.4815C24.8124 13.3334 24.4491 13.3334 23.7226 13.3334H14L15.3334 2.66669Z'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconBolt;
