import { IconProps } from '@/components/web/icons/types';

const IconMedal: React.FC<IconProps> = ({
  width = '32',
  height = '32',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.33333 20.1203V29.3333L15.6038 26.8251C15.7502 26.7666 15.8234 26.7373 15.8989 26.7257C15.9659 26.7154 16.0341 26.7154 16.1011 26.7257C16.1766 26.7373 16.2498 26.7666 16.3962 26.8251L22.6667 29.3333V20.1203M26 12.6667C26 18.1895 21.5228 22.6667 16 22.6667C10.4772 22.6667 6 18.1895 6 12.6667C6 7.14381 10.4772 2.66666 16 2.66666C21.5228 2.66666 26 7.14381 26 12.6667Z'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconMedal;
