import { FC } from 'react';

import { cn } from '@/lib/utils';

type IconProps = {
  variant?: 'onRedBg' | 'onWhiteBg';
  className?: string;
};

const IconLocationWithBg: FC<IconProps> = ({
  variant = 'onRedBG',
  className,
}) => {
  return (
    <div
      className={cn(
        'card-button-border inline-flex h-6 w-6 items-center justify-center rounded-full  ',
        [
          variant === 'onRedBg' && ' bg-white',
          variant === 'onWhiteBg' && 'bg-primary-500 ',
        ],
        className
      )}
    >
      <svg
        width='14'
        height='16'
        viewBox='0 0 14 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.99999 8.33337C8.10456 8.33337 8.99999 7.43794 8.99999 6.33337C8.99999 5.2288 8.10456 4.33337 6.99999 4.33337C5.89542 4.33337 4.99999 5.2288 4.99999 6.33337C4.99999 7.43794 5.89542 8.33337 6.99999 8.33337Z'
          stroke={variant === 'onRedBg' ? '#DD0B2F' : '#FFFFFF'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.99999 14.6667C8.33332 12 12.3333 10.2789 12.3333 6.66671C12.3333 3.72119 9.94551 1.33337 6.99999 1.33337C4.05447 1.33337 1.66666 3.72119 1.66666 6.66671C1.66666 10.2789 5.66666 12 6.99999 14.6667Z'
          stroke={variant === 'onRedBg' ? '#DD0B2F' : '#FFFFFF'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export default IconLocationWithBg;
