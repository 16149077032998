import { IconProps } from '@/components/web/icons/types';

const IconPeople: React.FC<IconProps> = ({
  width = '32',
  height = '32',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.3334 22L24 24.6667L29.3334 19.3333M16 18H10.6667C8.18167 18 6.93915 18 5.95904 18.406C4.65223 18.9473 3.61396 19.9855 3.07266 21.2924C2.66669 22.2725 2.66669 23.515 2.66669 26M20.6667 2.38768C22.6212 3.17886 24 5.09508 24 7.33333C24 9.57159 22.6212 11.4878 20.6667 12.279M18 7.33333C18 10.2789 15.6122 12.6667 12.6667 12.6667C9.72117 12.6667 7.33335 10.2789 7.33335 7.33333C7.33335 4.38781 9.72117 2 12.6667 2C15.6122 2 18 4.38781 18 7.33333Z'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconPeople;
