import { IconProps } from '@/components/web/icons/types';

const IconScale: React.FC<IconProps> = ({
  width = '16',
  height = '14',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.33331 10.3333V10.2332C1.33331 10.0222 1.33331 9.91671 1.34945 9.81393C1.36378 9.72268 1.38755 9.63315 1.42039 9.54681C1.45737 9.44957 1.50971 9.35797 1.61439 9.17477L3.99998 5M1.33331 10.3333C1.33331 11.8061 2.52722 13 3.99998 13C5.47274 13 6.66665 11.8061 6.66665 10.3333M1.33331 10.3333V10.2C1.33331 10.0133 1.33331 9.91997 1.36964 9.84867C1.4016 9.78595 1.4526 9.73496 1.51532 9.703C1.58662 9.66667 1.67996 9.66667 1.86665 9.66667H6.13331C6.32 9.66667 6.41334 9.66667 6.48464 9.703C6.54736 9.73496 6.59836 9.78595 6.63032 9.84867C6.66665 9.91997 6.66665 10.0133 6.66665 10.2V10.3333M3.99998 5L6.38557 9.17477C6.49025 9.35797 6.54259 9.44957 6.57957 9.54681C6.61241 9.63315 6.63618 9.72268 6.65051 9.81393C6.66665 9.91671 6.66665 10.0222 6.66665 10.2332V10.3333M3.99998 5L12 3.66667M9.33331 9V8.89987C9.33331 8.68888 9.33331 8.58338 9.34945 8.4806C9.36378 8.38934 9.38755 8.29982 9.42039 8.21348C9.45737 8.11623 9.50971 8.02463 9.61439 7.84144L12 3.66667M9.33331 9C9.33331 10.4728 10.5272 11.6667 12 11.6667C13.4727 11.6667 14.6666 10.4728 14.6666 9M9.33331 9V8.86667C9.33331 8.67998 9.33331 8.58664 9.36964 8.51534C9.4016 8.45262 9.4526 8.40162 9.51532 8.36966C9.58662 8.33333 9.67996 8.33333 9.86665 8.33333H14.1333C14.32 8.33333 14.4133 8.33333 14.4846 8.36966C14.5474 8.40162 14.5984 8.45262 14.6303 8.51534C14.6666 8.58664 14.6666 8.67998 14.6666 8.86667V9M12 3.66667L14.3856 7.84144C14.4902 8.02463 14.5426 8.11623 14.5796 8.21348C14.6124 8.29982 14.6362 8.38934 14.6505 8.4806C14.6666 8.58338 14.6666 8.68888 14.6666 8.89987V9M7.99998 1V4.33333'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconScale;
