import { FC } from 'react';

import { cn } from '@/lib/utils';

type IconProps = {
  variant?: 'onRedBg' | 'onWhiteBg';
  className?: string;
};

const IconArrowRight: FC<IconProps> = ({ variant = 'onRedBg', className }) => {
  return (
    <div
      className={cn(
        'border-blackShade-15 flex h-6 w-6 items-center justify-center rounded-full border  ',
        [
          variant === 'onRedBg' && ' bg-white',
          variant === 'onWhiteBg' && 'bg-primary-500 ',
        ],
        className
      )}
    >
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.33334 8.00004H12.6667M12.6667 8.00004L8.00001 3.33337M12.6667 8.00004L8.00001 12.6667'
          stroke={variant === 'onRedBg' ? '#DD0B2F' : '#FFFFFF'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

export default IconArrowRight;
