import { FC } from 'react';

import { cn } from '@/lib/utils';

type IconProps = {
  direction?: 'down' | 'right';
  className?: string;
};

const IconChevronWithBg: FC<IconProps> = ({ direction, className }) => {
  return (
    <div
      className={cn(
        'border-blackShade-5 bg-blackShade-5 flex h-6 w-6 items-center justify-center rounded-full border p-1',
        className
      )}
    >
      <div
        className={cn('flex h-4 w-4 items-center justify-center ', [
          direction === 'down' && 'rotate-90',
        ])}
      >
        <svg
          width='6'
          height='11'
          viewBox='0 0 6 11'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1 9.5L5 5.5L1 1.5'
            stroke='#736969'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  );
};

export default IconChevronWithBg;
