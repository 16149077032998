import { IconProps } from '@/components/web/icons/types';

const IconClose: React.FC<IconProps> = ({
  width = '10',
  height = '10',
  color = '#DD0B2F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 1L1 9M1 1L9 9'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconClose;
